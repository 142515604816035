.nav{
    display: flex;
    position: fixed;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 50;
    height: 80px;
    width: 100%;
}

.nav-titulo{
    display: flex;
    position: relative;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 30%;
}

.nav-titulo h1{
    margin: auto;
    font-family: sans-serif;
    font-size: 2em;
    color: white;
}

.nav-a a{
    cursor: pointer;
}

/* RESPONSIVE BTN */

.menu-responsive{
    display: flex;
    z-index: 20;
    position: absolute;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
}

.responsive-btn{
    display: flex;
    position: relative;
    height: 100%;
    width: 80px;
}

.btn-r{
    margin: auto;
    font-size: 3em;
    color: white;
}

/* RESPONSIVE MENU */

.responsive{
    display: none;
    position: absolute;
    flex-direction: column;
    z-index: -20;
    height: 100vh;
    width: 100%;
}

.responsive_div-f{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 70%;
    width: 100%;
    top: 80px;
}

.responsive_div-f a{
    cursor: pointer;
    color: white;
    font-size: 2em;
    text-decoration: none;
}

.responsive_div_s{
    display: flex;
    position: relative;
    height: 30%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.responsive_div_s img{
    height: 150px;
    filter: drop-shadow(2px 4px 6px #fefefe);
}
/* NORMAL MENU */

.nav-a{
    display: none;
    position: relative;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 20%;
}

.nav-a a{
    font-family: sans-serif;
    font-family: 1em;
    text-decoration: none;
    color: white;
    transition: transform 0.5s ease;
}

.nav-a a:hover{
    transform: scale(1.2);
}

@media(min-width: 1024px){
    
    .menu-responsive{
        display: none;
    }
    .nav-a{
        display: flex;
        margin: 0px 10% 0px 0px;
        width: 30%;
    }
}

@media(max-width: 500px){
    .nav-titulo{
        
        padding: 0px 0px 0px 10%;
        }
}