.section-principal, .section_absolute, .section_relative{
    display: flex;
    height: 100vh;
    width: 100%;
}

.section-principal{
    position: relative;
}

.section_absolute{
    position: absolute;
}

.section_relative{
    position: relative;
}

#section_absolute-img{
    display: block;
}

/* //SECCION RELATIVE// */

.div_relative-p {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    align-items: center;
}

.div_relative-content{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;

}

.div_relative-content h1{
    color: #fff;
    font-family: sans-serif;
    margin: 20px;
}
.div_relative-content p{
    color: #fff;
    font-family: sans-serif;
    margin: 20px;
}
.div_relative-content button{
    color: #fff;
    font-family: sans-serif;
    margin: 20px;
}

/* Titulo */

.div_relative-content h1{
    text-align: center;
    font-size: 4em;
}

.tituloDos__deTituloUno{
    display: flex;
    position: relative;
    right: -50px;
    margin: 0px;
    font-size: 1em;
    animation: desplasamiento ease 2s ;
}

@keyframes desplasamiento{
    0%{
        right: 50px;
    }

    100%{
        right: -50px;
    }
}

.div_relative-content p{
    text-align: center;
    font-size: 2em;
}
.div_relative-content button{
    height: 50px;
    width: 160px;
    font-size: 1.2em;
    border-color: #fff;
    background-color: transparent;
    cursor: pointer;
    bottom: 50px;
    transition: 0.5s;
}
.div_relative-content button:hover{
    transform: scale(1.1);
}

/* SECTION ABOSLUTE */

.section_absolute{
    flex-direction: column;
}

.img__absolute{
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#section_absolute-img{
    display: flex;
    position: relative;
    flex: 1;
}