/* Principal */
.inicio__uno{
    display: flex;
    position: relative;
    height: 100vh;
    width: 100%;
    justify-content: center;
}
/* Section absolute */
.inicio__uno_div_absolute{
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.inicio__uno_div_absolute_imgFondo{
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.inicio__uno_div_absolute_parteUno{
    display: flex;
    position: relative;
    height: 100vh;
    width: 30%;
    filter: drop-shadow(2px 4px 6px black);
    animation: desplazamientoD 1s ease;
}



/* Section relative */

.inicio__uno_div_relative{
    display: flex;
    position: relative;
    height: 100vh;
    width: 90%;
}

/* Inicio Parte Uno Relative */

.inicio__uno_div_relative_parteUno{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    width: 50%;
    z-index: 10;
    justify-content: center;
}

.inicio__uno_div_relative_parteUno__centro{
    display: flex;
    flex-direction: column;
    position: relative;
    /* height: 50%; */
    width: 100%;
    animation: desplazamientoD 1s ease;
}

.inicio__uno_div_relative_parteUno__centro h1{
    font-size: 6em;
    margin: 0px;
    font-family: sans-serif;
    filter: drop-shadow(2px 4px 6px black);
}

.inicio__uno_div_relative_parteUno__centro p {
    font-size: 2em;
    font-family: sans-serif;
    filter: drop-shadow(2px 4px 6px black);
}

.inicio__uno_div_relative_parteUno__centro a {
    display: block;
    position: relative;
    height: fit-content;
    width: fit-content;
    font-size: 2em;
    font-family: sans-serif;
    text-decoration: none;
    margin: 1em 0px;
    padding: 15px;
    border: 4px solid #fff;
    transition: all 1s ease;
}

.inicio__uno_div_relative_parteUno__centro a:hover{
    transform: scale(1.05);
}

/* Inicio Parte Dos Relative */
.inicio__uno_div_relative_parteDos{
    display: flex;
    position: relative;
    height: 100vh;
    width: 50%;
}



/* Animaciones */

@keyframes desplazamientoD{
    0%{
        right: 30%;
    }

    100%{
        right: 0%;
    }
}

@media (max-width:500px) {
    .inicio__uno_div_relative_parteUno{
        width: 100%;
    }
    .inicio__uno_div_relative_parteDos{
        position: absolute;
        width: 100%;
    }

    .inicio__uno_div_relative_parteUno__centro h1{
        width: 80%;
        font-size: 4em;
    }
    .inicio__uno_div_relative_parteUno__centro p{
        width: 70%;
        font-size: 1.5em;
    }

    .inicio__uno_div_absolute_parteUno{
        width: 50%;
    }
}