.footerUno {
    display: flex;
    position: relative;
    /* height: 20vh; */
    width: 100%;
    background-color: rgb(116, 77, 249);
}

.footerUno__relativeDiv{
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.footerUno__relativeDiv_DivUno{
    display: flex;
    position: relative;
    height: 75%;
    width: 50%;
}

.footerUno__relativeDiv_DivUno div{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.footerUno__relativeDiv_DivUno div h4 {
    font-family: sans-serif;
    width: 30%;
    color: #2e2e2e;
    text-align: center;
}

.footerUno__relativeDiv_DivUno div ul {
    display: flex;
    position: relative;
    flex-direction: row;
}

.footerUno__relativeDiv_DivUno div ul li {
    display: inline;
    font-family: sans-serif;
    cursor: pointer;
    margin: 0px 5px;
    text-decoration: none;
}

@media (max-width:500px) {
    .footerUno__relativeDiv_DivUno{
        width: 100%;
    }

    
}

@media(max-width:1000px) {
    .footerUno__relativeDiv_DivUno div h4{
        width: 35%;
    }
}