:root{
    --font-1 : ui-monospace;
    --font-family-title: 1.2em;
}
.section__dos{
    margin: 2% 0px;
}
/* SECTION ABSOLUTE  */

.section__dos-absolute{
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
}

.img__fondo{
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

}

.section__dos-absolute-img{
    display: flex;
    position: relative;
    height: 100%;
    flex: 1;
}

.section__dos-absolute-img img{
    display: flex;
    position: relative;
    height: auto;
    width: auto;
    filter: drop-shadow(2px 4px 6px black);
    object-fit: cover;

}

/* SECTION RELATIVE */
.section__dos, .section__dos_relative{
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;

}
/* posiciones de .section__dos_relative */
.position_uno{
    flex-direction: row;
}

.position_dos {
    flex-direction: row-reverse;
}

.section__dos_relative-div{
    display: flex;
    position: relative;
    flex: 1 ;
}
/* DIV RELATIVE UNO */
.div__relative-uno{
    flex: 2 ;
}


.div__relative-uno div{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.div__relative-uno div h2{
    text-align: center;
    font-family: var(--font-1);
    font-size: 3.5em;
}

.div__relative-uno div p{
    color: black;
    font-size: 3em;
    font-family: var(--font-1);
    max-width: 70%;
    margin: 0px 0px 50px 0px;
    text-align: center;
}

.div__relative-uno div a {
    display: flex;
    position: relative;
    height: 50px;
    width: 150px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: var(--font-family-title);
    cursor: pointer;
    margin: 0px 0px 5% 0px;
}

/* DIV RELATIVE DOS */

.div__relative-dos{
    flex: 2 ;
}
.div__relative-dos div{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.div__relative-dos div img{
    width: 500px;
    display: flex;
    filter: drop-shadow(2px 4px 6px black);
    border-radius: 10px;
    object-fit: cover;
}

@media (max-width:1024px) {
    .div__relative-uno div p{
        max-width: 90%;
    }

    .div__relative-uno div p{
        font-size: 2em;
    }

    .section__dos_relative{
        flex-direction: column;
    }

    .div__relative-dos{
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .div__relative-dos div img{
        width: 350px;
    }
}

@keyframes rotar {
    0% {
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}


