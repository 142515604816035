.componenteItem_section{
    display: flex;
    position: relative;
    min-height: 50vh;
    width: 100%;
    padding: 5% 0px;
}




/* SECTION RELATIVE */
.componenteItem_sectionRelative{
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.componenteItem_sectionRelative_div{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    width: 80%;
    justify-content: center;
    outline: 2px solid red;
}

.componenteItem_sectionRelative_divItem{
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: 500px;
    width: 350px;
    overflow: hidden;
    background-color: #fff;
    margin: 25px;
    /* border: 4px solid #3f3f3f; */
    border-radius: 10px;
    filter: drop-shadow(2px 4px 6px black);
    transition: all 1s;
}

.componenteItem_sectionRelative_divItem:hover{
    transform: scale(1.05);
}

.componenteItem_sectionRelative_divItem_Uno{
    display: flex;
    position: relative;
    height: 300px;
    width: 100%;
}

.componenteItem_sectionRelative_divItem_Uno img {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.componenteItem_sectionRelative_divItem_Dos{
    display: flex;
    position: relative;
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.componenteItem_sectionRelative_divItem_Dos h2{
    font-family: sans-serif;
    font-size: 2.5em;
    text-align: center;
}

.componenteItem_sectionRelative_divItem_Dos p {
    text-align: center;
    font-family: sans-serif;
    max-width: 80%;
    font-size: 1.5em;
}

.componenteItem_sectionRelative_divItem_Dos a{
    font-family: sans-serif;
    margin: 16px;
    text-decoration: none;
    border-radius: 15px;
    padding: 10px;
    font-size: 1.5em;
    border: 2px solid black;
    cursor: pointer;
    transition: all 0.5s;
}

.componenteItem_sectionRelative_divItem_Dos a:hover{
    transform: scale(1.05);
}

@media (max-width: 500px) {
    .componenteItem_sectionRelative{
        flex-direction: column;
    }
}
