.section__cinco {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    background-color: #3359F2;
}

.footer__divS {
    display: flex;
    position: relative;
    height: 300px;
    margin: 20px;
}

/* DIV UNO */

.footer__div__1 {
    justify-content: center;
    align-items: center;
}

.footerDiv1{
    display: flex;
    position: relative;
}

.footerDiv1__div1 {
    display: block;
    height: 250px;
    width: 250px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 4px solid #fff;
    overflow: hidden;
}

.footerDiv1__div1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.footerDiv1__div2 {
    display: flex;
    position: relative;
    max-width: 60%;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 40px;
}

.footerDiv1__div2DivH3 h3 {
    margin: 10px;
    font-family: sans-serif;
    font-size: 2em;
}

.footerDiv1__div2DivP p {
    margin: 10px;
    font-family: sans-serif;
    font-size: 1.5em;
}

.footerDiv1__div2DivIcons{
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-top: 30px;
    font-size: 50px;
}

.footerDiv1__div2DivIcons a{
    cursor: pointer;
}

.TiSocial__footer{
    color: black;
    transition: transform 0.5s ease;
    padding: 10px;
    border:2px solid black;
    border-radius: 10px;

    
}

.TiSocial__footer:hover{
    transform: scale(1.2);
}

/* DIV DOS */

.footer__div__2 {
    flex: 1;
}




/* DIV ABSOLUTE 3 */

.footer__divAbsolute_3{
    display: flex;
    position: absolute;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
}

.footer__divAbsoluteDiv_1 {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    overflow: hidden;
    height: 100%;
    width: 40%;
    background-color: aliceblue;
    clip-path: polygon(100% 16%, 0% 100%, 100% 100%);
}

.footer__divAbsoluteDiv_2{
    display: flex;
    position: relative;
    height: 83%;
    width: 100%;
}

.footer__divAbsoluteDiv_2 img{
    display: flex; 
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 2s;
}

.slide-in {
    transition: all 2s;
    transform: translateX(0%);
    }

.slide-out{
    transition: all 2s;
    transform: translateX(-100%);
    
}

@media(max-width: 1330px){
    .footer__div__1{
        flex: none;
        width: 80%;
    }
    .footer__divS{
        height: auto;
    }
    .footerDiv1{
        flex-direction: column;
    }
    .footerDiv1__div2{
        margin: 20px 0px;
    }


    .footer__divAbsoluteDiv_1{
        width: 70%;
    }
}

@media(max-width: 850px){
    .footer__divAbsoluteDiv_2{
        height: 60%;
    }
}
@media(max-width: 414px) {
    .footer__divAbsoluteDiv_1{
        width: 90%;
    }
    .footer__divAbsoluteDiv_2{
        height: 40%;
    }
}